<template>
  <v-layout>
    <v-container fluid class="my-0 py-0">
      <v-list-item three-line>
        <v-list-item-content>
          <p class="display-1 text--primary">
            {{ formTitle }}
          </p>
        </v-list-item-content>
      </v-list-item>
      <v-container fluid class="my-0 py-0">
        <v-form ref="form" v-model="valid1">
          <div>
            <v-row>
              <v-col cols="12">
                <v-card outlined>
                  <v-app-bar dense elevation="0" flat class="mb-4 neuter--text" color="gray">
                    <h3 class="font-weight-bold">{{ $t("records.titles.primary_location") }}</h3>
                  </v-app-bar>
                  <v-card-text>
                    <v-row>
                      <v-col cols="4">
                        <v-text-field
                          v-model="preRecordSelected.prerecords_name"
                          :counter="max"
                          :rules="requireAndMaxRules(max)"
                          class="required"
                          clearable
                          color="primary"
                          maxlength="max"
                          onkeypress="return (event.charCode != 34)"
                          required
                          v-on:keyup.86="replacePrerecordsName"
                          v-on:keyup.ctrl.86="replacePrerecordsName"
                          persistent-hint
                          small
                          flat
                          outlined
                          dense
                        >
                          <template v-slot:label>
                            {{ $t('pre-records.fields.name') }}
                            <strong class="red--text">*</strong>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete
                            v-model="preRecordSelected.storage_device_type_id"
                            :items="storageDeviceTypesActiveByCompany"
                            :label="$t('storage.fields.storage_type')"
                            :no-data-text="$t('common.fields.noDataAvailable')"
                            :rules="requireRules"
                            class="required"
                            clearable
                            color="primary"
                            item-color="primary"
                            item-text="storage_type_name"
                            return-object
                            small-chips
                            persistent-hint
                            small
                            flat
                            outlined
                            dense
                            @change="changeField($event, 'sdt')"
                        >
                          <template v-slot:label>
                            {{ $t('storage.fields.storage_type') }}
                            <strong class="red--text">*</strong>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete
                            v-model="preRecordSelected.process"
                            :disabled="!preRecordSelected.storage_device_type_id"
                            :items="processByStage"
                            :no-data-text="$t('common.fields.noDataAvailable')"
                            :rules="requireRules"
                            class="required"
                            clearable
                            color="primary"
                            item-color="primary"
                            item-text="proccess_name"
                            item-value="id"
                            return-object
                            small-chips
                            persistent-hint
                            small
                            flat
                            outlined
                            dense
                            @change="changeField($event,'process')"
                        >
                          <template v-slot:label>
                            {{ $t('pre-records.fields.process') }}
                            <strong class="red--text">*</strong>
                          </template>
                          <template v-slot:selection="{ item, index }">
                            <v-chip small>
                              <span>{{ item.proccess_name }}</span>
                            </v-chip>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    </v-card-text>
                  </v-card>
              </v-col>
              <v-col cols="12">
                <v-card outlined>
                  <v-app-bar dense elevation="0" flat class="mb-4 neuter--text" color="gray">
                    <h3 class="font-weight-bold">{{ $t("inventory.titles.locations") }}</h3>
                  </v-app-bar>
                  <v-card-text>
                    <v-row>
                      <v-col cols="4">
                        <v-autocomplete
                            v-model="preRecordSelected.location_origin"
                            :clearable="true"
                            :disabled="!preRecordSelected.storage_device_type_id"
                            :hide-selected="true"
                            :items="origin.locations"
                            :no-data-text="$t('common.fields.noDataAvailable')"
                            color="primary"
                            item-text="name"
                            item-value="locationId"
                            return-object
                            small-chips
                            persistent-hint
                            small
                            flat
                            outlined
                            dense
                            :loading="loadings.origin.locations"
                            @click:clear="fetchLocations('', 'origin')"
                            @change="fetchStorages(null, preRecordSelected.location_origin, 'origin')"
                            @keypress.enter="fetchLocations($event.target.value, 'origin')"
                        >
                          <template v-slot:label>
                            {{ $t('pre-records.fields.location_origin') }}
                          </template>
                          <template v-slot:prepend-item>
                            <v-alert
                              color="primary"
                              type="info"
                              dense
                              border="right"
                              text
                              tile
                              class="ma-0"
                            >
                              <template #append>
                                <v-icon color="primary" v-text="'mdi-keyboard-return'"/>
                              </template>
                              <template #prepend>
                                <v-icon class="mr-2" color="primary" v-text="'mdi-information-slab-circle-outline'"/>
                              </template>
                              {{ $t('common.fields.startTyping') }}
                            </v-alert>
                          </template>
                          <template v-slot:selection="{ item, index }">
                            <v-chip small>
                              <span>{{ item.name | truncate(50) }}</span>
                            </v-chip>
                          </template>
                          <template v-slot:item="{ item, attrs }">
                            <div style="max-width: 300px">
                              <read-more
                                :classes="'mb-0'"
                                :font-size="14"
                                :max-chars="80"
                                :text="item.name"
                              />
                            </div>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete
                            v-model="preRecordSelected.sdi_origin"
                            :clearable="true"
                            :disabled="!preRecordSelected.storage_device_type_id"
                            :items="origin.storages"
                            :no-data-text="$t('common.fields.noDataAvailable')"
                            color="primary"
                            item-color="primary"
                            item-text="control_label"
                            item-value="storageDeviceId"
                            persistent-hint
                            small
                            flat
                            outlined
                            dense
                            return-object
                            small-chips
                            @change="selectSDTisFarm"
                            @click:clear="fetchStorages('', preRecordSelected.location_origin, 'origin')"
                            @keypress.enter="fetchStorages($event.target.value, preRecordSelected.location_origin, 'origin')"
                        >
                          <template v-slot:label>
                            {{ $t('pre-records.fields.originReg') }}
                          </template>
                          <template v-slot:prepend-item>
                            <v-alert
                              color="primary"
                              type="info"
                              dense
                              border="right"
                              text
                              tile
                              class="ma-0"
                            >
                              <template #append>
                                <v-icon color="primary" v-text="'mdi-keyboard-return'"/>
                              </template>
                              <template #prepend>
                                <v-icon class="mr-2" color="primary" v-text="'mdi-information-slab-circle-outline'"/>
                              </template>
                              {{ $t('common.fields.startTyping') }}
                            </v-alert>
                          </template>
                          <template
                            v-slot:selection="{ item, index, attrs, select, selected }"
                          >
                            <v-chip v-if="index === 0" small>
                              <span>{{ item.control_label }}</span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text caption"
                            >(+{{ preRecordSelected.sdi_origin.length - 1 }}
                      {{ $t("common.titles.others") }})</span
                            >
                          </template>
                          <template v-slot:item="{ item, attrs }">
                            <div style="max-width: 360px">
                              <v-checkbox
                                v-if="
                          preRecordSelected.process
                            ? preRecordSelected.process.processType ===
                                'AGRICULTURAL' && editedIndex === -1
                            : false
                        "
                                :hide-details="true"
                                :input-value="attrs.inputValue"
                                class="pt-2 pb-2 mt-0"
                                color="primary"
                              >
                                <template v-slot:label>
                                  <read-more
                                    :classes="'mb-0'"
                                    :font-size="14"
                                    :max-chars="80"
                                    :text="item.control_label"
                                  />
                                </template>
                              </v-checkbox>
                              <read-more
                                v-else
                                :classes="'mb-0'"
                                :font-size="14"
                                :max-chars="80"
                                :text="item.control_label"
                              />
                            </div>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row
                        v-if="
                        preRecordSelected.process &&
                        preRecordSelected.process.processType !== 'AGRICULTURAL'
                      "
                    >
                      <v-col cols="4">
                        <v-autocomplete
                            v-model="preRecordSelected.location_destination"
                            :clearable="true"
                            :disabled="!preRecordSelected.storage_device_type_id"
                            :hide-selected="true"
                            :items="destination.locations"
                            :no-data-text="$t('common.fields.noDataAvailable')"
                            color="primary"
                            item-text="name"
                            item-value="locationId"
                            persistent-hint
                            small
                            flat
                            outlined
                            dense
                            return-object
                            small-chips
                            :loading="loadings.origin.locations"
                            @click:clear="fetchLocations('', 'destination')"
                            @change="fetchStorages(null, preRecordSelected.location_destination, 'destination', true)"
                            @keypress.enter="fetchLocations($event.target.value, 'destination')"
                        >
                          <template v-slot:label>
                            {{ $t('pre-records.fields.location_destination') }}
                          </template>
                          <template v-slot:prepend-item>
                            <v-alert
                              color="primary"
                              type="info"
                              dense
                              border="right"
                              text
                              tile
                              class="ma-0"
                            >
                              <template #append>
                                <v-icon color="primary" v-text="'mdi-keyboard-return'"/>
                              </template>
                              <template #prepend>
                                <v-icon class="mr-2" color="primary" v-text="'mdi-information-slab-circle-outline'"/>
                              </template>
                              {{ $t('common.fields.startTyping') }}
                            </v-alert>
                          </template>
                          <template v-slot:selection="{ item, index }">
                            <v-chip small>
                              <span>{{ item.name | truncate(50) }}</span>
                            </v-chip>
                          </template>
                          <template v-slot:item="{ item, attrs }">
                            <div style="max-width: 300px">
                              <read-more
                                :classes="'mb-0'"
                                :font-size="14"
                                :max-chars="80"
                                :text="item.name"
                              />
                            </div>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete
                            v-model="preRecordSelected.sdi_destination"
                            :clearable="true"
                            :disabled="!preRecordSelected.location_destination"
                            :items="destination.storages"
                            :no-data-text="$t('common.fields.noDataAvailable')"
                            :multiple="
                              preRecordSelected.process
                                ? preRecordSelected.process.processType === 'AGRICULTURAL'
                                : false
                            "
                            color="primary"
                            item-color="primary"
                            item-text="control_label"
                            item-value="storageDeviceId"
                            persistent-hint
                            small
                            flat
                            outlined
                            dense
                            return-object
                            small-chips
                            @click:clear="fetchStorages('', preRecordSelected.location_destination, 'destination', true)"
                            @keypress.enter="fetchStorages($event.target.value, preRecordSelected.location_destination, 'destination', true)"
                        >
                          <template v-slot:label>
                            {{ $t('pre-records.fields.destinationRed') }}
                          </template>
                          <template v-slot:prepend-item>
                            <v-alert
                              color="primary"
                              type="info"
                              dense
                              border="right"
                              text
                              tile
                              class="ma-0"
                            >
                              <template #append>
                                <v-icon color="primary" v-text="'mdi-keyboard-return'"/>
                              </template>
                              <template #prepend>
                                <v-icon class="mr-2" color="primary" v-text="'mdi-information-slab-circle-outline'"/>
                              </template>
                              {{ $t('common.fields.startTyping') }}
                            </v-alert>
                          </template>
                          <template
                            v-slot:selection="{ item, index, attrs, select, selected }"
                          >
                            <v-chip v-if="index === 0" small>
                              <span>{{ item.control_label }}</span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text caption"
                            >(+{{ preRecordSelected.sdi_destination.length - 1 }}
                      {{ $t("common.titles.others") }})</span
                            >
                          </template>
                          <template v-slot:item="{ item, attrs }">
                            <div style="max-width: 360px">
                              <v-checkbox
                                v-if="
                          preRecordSelected.process
                            ? preRecordSelected.process.processType ===
                              'AGRICULTURAL'
                            : false
                        "
                                :hide-details="true"
                                :input-value="attrs.inputValue"
                                class="pt-2 pb-2 mt-0"
                                color="primary"
                              >
                                <template v-slot:label>
                                  <read-more
                                    :classes="'mb-0'"
                                    :font-size="14"
                                    :max-chars="80"
                                    :text="item.control_label"
                                  />
                                </template>
                              </v-checkbox>
                              <read-more
                                v-else
                                :classes="'mb-0'"
                                :font-size="14"
                                :max-chars="80"
                                :text="item.control_label"
                              />
                            </div>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-form>
        <section v-if="existStructures || existGroups">
          <v-layout align-content-center row align-center justify-space-between class="mx-0"
          >
            <v-row v-if="existStructures" class="py-0 px-3">
              <v-col class="mt-8 mb-0 px-0" cols="12">
                <v-card
                  outlined
                  class="mb-3"
                >
                  <v-app-bar dense elevation="0" flat class="mb-4 neuter--text" color="gray">
                    <h3 class="font-weight-bold">{{ $t("records.titles.general") }}</h3>
                  </v-app-bar>
                  <v-card-text>
                    <LoadingGroupComponent :is-loading="loadingSimpleStructures" />
                    <v-form ref="formGroup" v-model="valid2">
                      <v-row>
                        <v-col
                            cols="4"
                            v-for="(value, index) in paginatedItems"
                        >
                          <SingleStructureComponent
                              :is-group="false"
                              :nomenclators="nomenclators"
                              :key="index"
                              :valid="valid2"
                              @update:structure="$set(preRecordSelected.recordStructureValuesOrganized.structures[index], 'structure', $event)"
                              :config="config"
                              :value="value"
                              :ref-key="index"
                          />

                        </v-col>
                      </v-row>
                    </v-form>
                    <FooterPaginationComponent
                        :amount="12"
                        :valid="valid2"
                        :page="currentStructurePage"
                        :per-page="structuresPerPage"
                        :values="getStructures"
                        :items-per-page="[12, 24, 38]"
                        @update:items="updateItems"
                    />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-layout>
          <v-layout align-content-center row align-center justify-space-between>
            <v-row v-if="existGroups" class="my-8 px-3">
              <v-col class="py-0 mb-12" cols="12">
                <v-card
                  outlined
                  v-for="(group, index) in getGroups"
                  :key="index"
                  class="mb-3"
                >
                  <v-card-title class="pa-0 gray">
                    <v-list-item class="pr-0">
                      <v-list-item-title class="neuter--text">
                          <h3 class="font-weight-bold">
                            {{ translate(group.languageKey, true) }}
                          </h3>
                      </v-list-item-title>
                      <v-badge
                          v-if="!(editedIndex !== -1 && group.groupCode === 'REEN')"
                          :content="group.rows.length"
                          color="neuter"
                          overlap
                          left
                          bordered
                      >
                        <v-card-actions class="pa-0">
                          <v-btn height="5.2vh" tile elevation="0" color="neuter" dense :disabled="!valid[group.id]">
                            <v-icon
                                color="white"
                                large
                                style='font-size: 24px'
                                @click.prevent="addGroupRow(group, index)"
                            >mdi-view-grid-plus-outline
                            </v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-badge>
                    </v-list-item>
                  </v-card-title>
                  <v-divider></v-divider>
                  <GroupStructuresComponent
                      :is-group="true"
                      :valid="valid[group.id]"
                      @validation:update="
                        updateValidation($event.valid, $event.group)
                      "
                      :config="config"
                      :rows="group.rows"
                      :nomenclators="nomenclators"
                      :group="group"
                      @delete:group="deleteGroupRow(...$event)"
                  />
                </v-card>
              </v-col>
            </v-row>
          </v-layout>
        </section>
      </v-container>
    </v-container>
    <v-footer absolute>
      <v-spacer></v-spacer>
      <v-btn
          :disabled="loading"
          class="mr-1"
          color="neuter"
          elevation="0"
          text
          link
          small
          @click="cancel"
      >
        {{ $t("common.buttons.cancel") }}
      </v-btn>
      <v-btn
          :disabled="!valid1 || validGroup > 0 || !valid2"
          :loading="loading"
          class="mr-4 t-bw-primary--text"
          elevation="0"
          color="primary"
          small
          @click="prepareToSave"
      >
        {{ $t("common.buttons.save") }}
      </v-btn>
    </v-footer>
  </v-layout>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import PermisionService from '../../services/PermisionService'
import i18n from '@/plugins/i18n'
import moment from 'moment'
import _ from 'lodash'
import LanguajeService from '../../services/LanguajeService'
import sortList from '../../services/OrderListService'
import ListComponent from '../records/components/ListComponent'
import NomenclatorComponent from '../records/components/NomenclatorComponent'
import SingleStructureComponent from "@/module/prerecords/commons/SingleStructureComponent";
import FooterPaginationComponent from "@/module/prerecords/commons/FooterPaginationComponent";
import LoadingGroupComponent from "@/module/prerecords/commons/LoadingGroupComponent";
import GroupStructuresComponent from "@/module/prerecords/commons/GroupStructuresComponent";
import ReadMore from "@/components/ReadMoreComponent.vue";

export default {
  name: 'pre-modal-records',
  components: {
    ReadMore,
    GroupStructuresComponent,
    LoadingGroupComponent,
    FooterPaginationComponent, SingleStructureComponent, NomenclatorComponent, ListComponent},
  data() {
    return {
      storageData: [],
      storage_device_origin: [],
      storage_device_destination: [],
      // User profile
      profile: null,
      valid1: false,
      valid: {},
      max: 40,
      // Record Data Model
      editedIndex: -1,
      // Locations Events
      // Evidences of record
      documents: [],
      flagGroups: false,
      config: null,
      loadingSimpleStructures: false,
      currentStructurePage: 1,
      structuresPerPage: 12,
      loading: false,
      nomenclators: [
        "PRODUCTSIMPLE",
        "EQUIPMENTSIMPLE",
        "OPERATORSIMPLE",
        "INPUTSIMPLE",
        "SEASONSIMPLE",
        "MEASUREMENTUNITSIMPLE",
        "PRODUCT",
        "EQUIPMENT",
        "OPERATOR",
        "INPUT",
        "SEASON",
        "MEASUREMENTUNIT",
      ],
      valid2: true,
      processByStage: [],
        loadings: {
          origin: {
            locations: false,
            storages: false
          },
          destination: {
            locations: false,
            storages: false
          }
        },
        origin: {
          locations: null,
          storages: null
        },
        destination: {
          locations: null,
          storages: null
        }
    }
  },
  created() {
    this.profile = JSON.parse(localStorage.getItem('profile'))
    this.config = JSON.parse(localStorage.getItem('config'))
    let index = JSON.parse(localStorage.getItem('prerecords_editOrSave'))
    if (index > -1) {
      this.editedIndex = index
    } else {
      this.preRecordSelected.record_date_time = new Date()
          .toISOString()
          .substr(0, 10)
    }
    if (this.editedIndex !== -1) {
      this.storageData = this.allStoragesByUser
      this.editItem(this.preRecordSelected)
    } else {
      this.storageData = _.filter(this.allStoragesByUser, {
        active: true,
      })
    }
  },
  computed: {
    paginatedItems() {
      const startIndex =
          (this.currentStructurePage - 1) * this.structuresPerPage
      const endIndex = startIndex + this.structuresPerPage

      return this.getStructures.slice(startIndex, endIndex)
    },
    preRecordSelected: {
      get() {
        return this.$store.getters['prerecords/preRecord']
      },
      set(val) {
        this.$store.commit('prerecords/SET_PRE_RECORD', val)
      },
    },
    storageDeviceTypesActiveByCompany() {
      return sortList.orderListByUppercase(
          this.$store.getters['storage_type/storageDeviceTypesActiveByCompany'],
          'storage_type_name'
      )
    },
    validGroup() {
      const cant = _.filter(this.valid, (o) => {
        return o === false
      })
      return cant.length
    },
    ...mapGetters({
      authoritiesProcess: 'authority/authoritiesProcess',
      allProcess: "proccess/proccess",
      locationsList: "locations/locationsList",
      storagesList: "storage/storagesList",
      allStoragesByUser: 'authority/authoritiesStoragesDevices',
      requireAndMaxRules: 'general/requireAndMaxRules',
      requireRules: 'general/requireRules',
      requireAndLotRules: 'general/requireAndLotRules',
      lotRules: 'general/lotRules',
      languageStructure: 'general/languageStructure'
    }),
    // Form condition title
    decimalFormat() {
      return (value) => (!['CI', 'CO', 'C'].includes(value.structure.structureCode) && value.structure.afterPoint !== null)
          ? value.structure.afterPoint : this.config.company.traceability.structure.number.afterPoint !== null
              ? parseInt(this.config.company.traceability.structure.number.afterPoint)
              : 3
    },
    formTitle() {
      return this.editedIndex === -1
          ? i18n.t('pre-records.titles.add_title')
          : i18n.t('pre-records.titles.edit_title')
    },
    // Exist structures
    existStructures() {
      return (
          this.preRecordSelected.recordStructureValuesOrganized &&
          this.preRecordSelected.recordStructureValuesOrganized.structures &&
          this.preRecordSelected.recordStructureValuesOrganized.structures
              .length > 0
      )
    },
    // Exist groups
    existGroups() {
      return (
          this.preRecordSelected.recordStructureValuesOrganized &&
          this.preRecordSelected.recordStructureValuesOrganized.groups &&
          this.preRecordSelected.recordStructureValuesOrganized.groups.length >
          0
      )
    },
    // Get structures
    getStructures() {
      return this.preRecordSelected.recordStructureValuesOrganized.structures
          ? this.preRecordSelected.recordStructureValuesOrganized.structures
          : []
    },
    // Get groups
    getGroups() {
      if (this.flagGroups) this.flagGroups = false
      return this.preRecordSelected.recordStructureValuesOrganized.groups
          ? _.map(
              this.preRecordSelected.recordStructureValuesOrganized.groups,
              (g, index) => ({ ...g, id: index++ })
          )
          : []
    },
  },
  methods: {
    ...mapActions({
      saveRecord: 'prerecords/saveRecord',
      searchProcessStructure: "proccess/searchProcessStructure",
      fetchPrimaryLocations: "locations/fetchFilteredLocations",
      fetchStorageLocations: "storage/fetchFilteredStorages"
    }),
    async fetchLocations(e, target) {
      this.$set(this[target], 'storages', [])

      this.$set(this.loadings[target], 'locations', true);

      await this.fetchPrimaryLocations([{
        stringFilter: e || "",
        isActive: true }, this.$toast
      ]).then(() => {
        if (this.preRecordSelected && this.preRecordSelected.id) {
          if (target === 'origin') {
            this.$set(this.preRecordSelected, 'location_origin', null);
            this.$set(this.preRecordSelected, 'sdi_origin', null);
          }
          if (target === 'destination') {
            this.$set(this.preRecordSelected, 'location_destination', null);
            this.$set(this.preRecordSelected, 'sdi_destination', null);
          }
        }

        this.$set(this[target], 'locations', this.locationsList)
      })
        .finally(() => {
          this.$set(this.loadings[target], 'locations', false);
        })
    },
    async fetchStorages(e, pl, target, isProductive = true) {
      if (pl) {
        this.$set(this[target], 'storages', [])

        this.$set(this.loadings[target], 'storages', true);

        await this.fetchStorageLocations([{
          primaryLocationVersionIds: [pl.id],
          stringFilter: e || "",
          isActive: true,
          productive: isProductive
        }, this.$toast]).then(() => {
          if (this.preRecordSelected && this.preRecordSelected.id) {
            if (target === 'origin') {
              this.$set(this.preRecordSelected, 'sdi_origin', null);
            }
            if (target === 'destination') {
              this.$set(this.preRecordSelected, 'sdi_destination', null);
            }
          }

          this.$set(this[target], 'storages', this.storagesList)
        }).finally(() => {
          this.$set(this.loadings[target], 'storages', false);
        })
      }
    },
    updateValidation(valid, group) {
      this.$set(this.valid, group, valid)
    },
    async updateItems(newOptions) {
      this.currentStructurePage = newOptions.page
      this.structuresPerPage = newOptions.itemsPerPage

      const promises = []

      _.forEach(this.paginatedItems, (object, index) => {
        if (this.nomenclators.includes(object.structure.structure_type))
          promises.push(
              this.searchProcessStructure([
                object.recordStructureValue.record_value,
                object.recordStructureValue.processStructureId.id,
                this.source,
              ])
                  .then(res => {
                    this.$set(this.recordSelected.recordStructureValuesOrganized.structures[index], "structure", {
                      dataToSelect: res.dataToSelect,
                      ...object.structure,
                      ...res,
                    })
                  })
                  .catch(console.log)
          )
      })

      await Promise.all(promises).finally(() => {
        this.loadingSimpleStructures = false
      })
    },
    // Translate function (consume Language Service)
    translate(key) {
      return LanguajeService.getKey3(key)
    },
    // Translate data process for edit records
    translateProcessData() {
      this.preRecordSelected.process.structures = _.map(
          this.preRecordSelected.process.structures,
          structure => {
            structure.dataToSelect =
                structure.dataToSelect.length > 0
                    ? _.map(structure.dataToSelect, details => {
                      return {
                        ...details,
                        name: LanguajeService.getKey3(details.name),
                      }
                    })
                    : structure.dataToSelect
            return structure
          }
      )
    },
    // Get Record Structure Values Save Model
    getRecordStructureValueModel() {
      return _.cloneDeep({
        groups: {
          key: 'groups',
          values: [],
        },
        structures: {
          key: 'structures',
          values: [],
        },
      })
    },
    // Computed getter processes by stage (Storage Device Type)
    processBySelectedStage(storageSelected) {
      this.processByStage = storageSelected
          ? _.filter(this.authoritiesProcess, process => {
            return (
                process.sdt_to_show.id ===
                storageSelected.storage_device_type_id
            )
          })
          : []
    },
    // Prepare record to edit
    async editItem(data) {
      let item = Object.assign({}, data)
      this.preRecordSelected.storage_device_type_id =
          this.storageDeviceTypesActiveByCompany.find(
              x => x.storage_device_type_id === item.process.sdt_to_show.id
          )

      this.processBySelectedStage(this.preRecordSelected.storage_device_type_id)

      this.preRecordSelected.process = this.allProcess.find(
          x => x.id === item.process.id
      )

      if (this.preRecordSelected.sdi_origin) {
        this.preRecordSelected.location_origin = _.cloneDeep(
          item.sdi_origin.primaryLocation
        )

        await this.fetchPrimaryLocations([{
          versionIds: [this.preRecordSelected.sdi_origin.primaryLocation.id],
          onlyCurrentVersion: false
        },
          this.$toast]).then(() => {
          this.$set(this.origin, 'locations', this.locationsList)
        })

        await this.fetchStorageLocations([{
          primaryLocationVersionIds: [this.preRecordSelected.sdi_origin.primaryLocation.id],
          versionIds: [this.preRecordSelected.sdi_origin.id],
          onlyCurrentVersion: false
        },
          this.$toast]).then(() => {
          this.$set(this.origin, 'storages', this.storagesList)
        })
      }
      if (this.preRecordSelected.sdi_destination) {
        this.preRecordSelected.location_destination = _.cloneDeep(
          item.sdi_destination.primaryLocation
        )

        await this.fetchPrimaryLocations([{
          versionIds: [this.preRecordSelected.sdi_destination.primaryLocation.id],
          onlyCurrentVersion: false
        },
          this.$toast]).then(() => {
          this.$set(this.destination, 'locations', this.locationsList)
        })

        await this.fetchStorageLocations([{
          primaryLocationVersionIds: [this.preRecordSelected.sdi_destination.primaryLocation.id],
          versionIds: [this.preRecordSelected.sdi_destination.id],
          onlyCurrentVersion: false
        },
          this.$toast]).then(() => {
          this.$set(this.destination, 'storages', this.storagesList)
        })
      }

      // structures
      _.forEach(
          item.recordStructureValuesOrganized.structures,
          async object => {
            if (
                ["DATA", "TIME", "DATATIME"].includes(
                    object.structure.structure_type
                )
            ) {
              if (
                  object.recordStructureValue.record_value[0] &&
                  object.recordStructureValue.record_value[0].length > 0 &&
                  object.recordStructureValue.record_value[0] !== null
              ) {
                let date = object.recordStructureValue.record_value[0].slice(
                    0,
                    19
                )
                object.recordStructureValue.record_value[0] = new Date(date)
              } else {
                object.recordStructureValue.record_value = []
              }
            } else if (
              object.structure.structure_type === "NUMBER"
            ) {
              if (object.recordStructureValue.record_value.length > 0) {
                if (object.recordStructureValue.record_value[0] === null ||
                  object.recordStructureValue.record_value[0] === "") {
                  object.recordStructureValue.record_value[0] = null
                }
              } else {
                object.recordStructureValue.record_value = [null]
              }
            }
          }
      )

      _.forEach(
        item.recordStructureValuesOrganized.groups,
        (group, index) => {
          _.forEach(group.rows, row => {
            _.forEach(row.values, async item => {
              if (
                item.structure.structure_type === "NUMBER"

              ) {
                if (item.recordStructureValue.record_value.length > 0) {
                  if (item.recordStructureValue.record_value[0] === null ||
                    item.recordStructureValue.record_value[0] === "") {
                    item.recordStructureValue.record_value[0] = null
                  }
                } else {
                  item.recordStructureValue.record_value = [null]
                }
              }
            })
          })

          _.forEach(group.rows, row => {
            _.forEach(row.values, async value => {
              if (
                ["DATA", "TIME", "DATATIME"].includes(
                  value.structure.structure_type
                )
              ) {
                if (
                  value.recordStructureValue.record_value[0] &&
                  value.recordStructureValue.record_value[0].length > 0
                ) {
                  let date = value.recordStructureValue.record_value[0].slice(
                    0,
                    19
                  )
                  value.recordStructureValue.record_value[0] = new Date(date)
                } else {
                  value.recordStructureValue.record_value = []
                }
              }
            })
          })
        }
      )
      if (
          this.preRecordSelected.process.processType === "AGRICULTURAL" &&
          this.editedIndex === -1
      ) {
        this.preRecordSelected.sdi_origin = [this.preRecordSelected.sdi_origin]
        this.preRecordSelected.sdi_destination = [
          this.preRecordSelected.sdi_destination,
        ]
      }
      this.preRecordSelected.record_date_time = item.record_date_time
          ? item.record_date_time
          : new Date()
      this.preRecordSelected.evidences = this.preRecordSelected.evidences
          ? this.preRecordSelected.evidences
          : {}
      this.translateProcessData()
      if (
          this.preRecordSelected.evidences &&
          this.preRecordSelected.evidences.length > 0
      )
        this.getEvidences(this.preRecordSelected.id)

      const promises = []

      this.loadingSimpleStructures = true

      _.forEach(this.paginatedItems, (object, index) => {
        if (this.nomenclators.includes(object.structure.structure_type))
          promises.push(
              this.searchProcessStructure([
                object.recordStructureValue.record_value,
                object.recordStructureValue.processStructureId.id,
                this.source,
              ])
                  .then(res => {
                    this.$set(this.preRecordSelected.recordStructureValuesOrganized.structures[index], "structure", {
                      dataToSelect: res.dataToSelect,
                      ...object.structure,
                      ...res,
                    })
                  })
                  .catch(console.log)
          )
      })

      await Promise.all(promises).finally(() => {
        this.loadingSimpleStructures = false
      })

      this.resetValidationGroups()
    },

    resetValidationGroups() {
      if (this.$refs && this.$refs.length > 0) {
        if (this.$refs.formStructures)
          this.$refs.formStructures.resetValidation()
      }
    },
    // Add group action on details (structures) step
    addGroupRow(group, index) {
      const rowCopy = {
        values: _.map(_.cloneDeep(group.rows[0].values), value => {
          if (value.structure.structure_type === "NUMBER") {
            value.recordStructureValue.record_value[0] = null
          } else {
            value.recordStructureValue.record_value = []
          }
          return value
        }),
        groupRow: 1,
      }

      this.$set(group, 'rows', _.forEach(group.rows, row => {
        row.groupRow = row.groupRow + 1
      }))

      group.rows.splice(0, 0, rowCopy)

      this.$root.$emit("update:page-group", {
        page: 1,
        itemsPerPage: 10,
        isAdd: true
      })

      this.flagGroups = true
    },
    // Delete group action on details (structures) step
    deleteGroupRow(rows, index) {
      this.rowData = {
        rows: rows,
        index: index,
      }

      if (this.rowData) {
        let { rows, index } = this.rowData;
        rows.forEach((row, i) => {
          if (i > index) {
            row.groupRow -= 1;
          }
        });
        rows.splice(index, 1);
        this.flagGroups = true;
        this.rowData = null;
      }
    },
    // Event action on select Stages (Storage Device Type)
    async selectSDTisFarm() {
      if (
        this.preRecordSelected.storage_device_type_id &&
        this.preRecordSelected.storage_device_type_id.isFarm
      ) {
        this.destination.locations = this.origin.locations
        this.preRecordSelected.location_destination = this.preRecordSelected.location_origin

        this.destination.storages = this.origin.storages
        this.preRecordSelected.sdi_destination = this.preRecordSelected.sdi_origin
      }
    },
    // Event action on change some field on form
    changeField(stage, event) {
      if (event === "sdt") {
        this.processBySelectedStage(stage)
        this.preRecordSelected.process = null
        this.preRecordSelected.recordStructureValuesOrganized =
          this.getRecordStructureValueModel()
      } else if (event === "process") {
        if (stage) {
          if (this.preRecordSelected.process === undefined) {
            this.preRecordSelected.recordStructureValuesOrganized = null
          } else {
            this.preRecordSelected.recordStructureValuesOrganized = _.cloneDeep(
              this.preRecordSelected.process.processStructureOrganized
            )
            if (
              this.preRecordSelected.recordStructureValuesOrganized.structures &&
              this.preRecordSelected.recordStructureValuesOrganized.structures
                .length > 0
            ) {
              _.forEach(
                this.preRecordSelected.recordStructureValuesOrganized.structures,
                item => {
                  if (item.structure.structure_type === "NUMBER") {
                    item.recordStructureValue.record_value[0] = null
                  }
                }
              )
            }
            if (
              this.preRecordSelected.recordStructureValuesOrganized.groups &&
              this.preRecordSelected.recordStructureValuesOrganized.groups.length >
              0
            ) {
              _.forEach(
                this.preRecordSelected.recordStructureValuesOrganized.groups,
                (group, index) => {
                  group["index"] = index + 1
                  _.forEach(group.rows, (row, index) => {
                    _.forEach(row.values, item => {
                      if (item.structure.structure_type === "NUMBER") {
                        item.recordStructureValue.record_value[0] = null
                      }
                    })
                  })
                }
              )
            }
          }
        } else {
          this.preRecordSelected.recordStructureValuesOrganized = []
        }
      } else {
        this.preRecordSelected.recordStructureValuesOrganized = []
      }
      this.preRecordSelected.location_origin = null
      this.preRecordSelected.sdi_origin = null
      this.preRecordSelected.location_destination = null
      this.preRecordSelected.sdi_destination = null
      this.$refs.form.resetValidation()
    },
    // Bug refresh select, (retirar al encontrar solución)
    bugRefresh() {
      console.log('refresh')
    },
    // Cancel registry record operation
    cancel() {
      this.$store.commit('prerecords/SET_ORIGINAL_STATE_PRE_RECORD')
      this.$router.push('/prerecords')
    },
    // Prepare data to save Records
    async prepareToSave() {
      const item = Object.assign({}, this.preRecordSelected)
      let items = {recordList: []}
      // Translate
      item.languageKey = LanguajeService.setKey3(
          item.prerecords_name,
          item.languageKey,
          !item.id ? this.languageStructure : null
      )
      // *****
      item.company = {id: this.profile.company_id}
      item.person_in_charge = {id: this.profile.id}
      item.storage_device_type_id =
          item.storage_device_type_id.storage_device_type_id
      const currentTime = moment()
      item.record_date_time = moment(currentTime).format(
          'YYYY-MM-DD HH:mm:ss'
      )
      item.process = {id: item.process.id, version: item.process.version}
      item.evidences = []
      const document = {
        documentAuthority: {id: 1},
        documentType: {id: 1},
        documentDescription: {id: 1},
        company: {id: item.company.id},
      }
      if (this.preRecordSelected.process.processType === 'AGRICULTURAL') {
        item.sdi_origin = item.sdi_origin ? {id: item.sdi_origin.id} : null
        item.sdi_destination = item.sdi_origin
            ? {id: item.sdi_origin.id}
            : null
      }
      if (
          this.preRecordSelected.process.processType === 'SHIPPING' ||
          this.preRecordSelected.process.processType === 'RECEIPT' ||
          this.preRecordSelected.process.processType === 'PROCESSING'
      ) {
        item.sdi_origin = item.sdi_origin ? {id: item.sdi_origin.id} : null
        item.sdi_destination = item.sdi_destination
            ? {id: item.sdi_destination.id}
            : null
      }
      let structuresCopy = _.cloneDeep(item.recordStructureValuesOrganized)
      let structures = []
      _.forEach(structuresCopy.structures, structure => {
        this.transformStructure(structure)
        structure.recordStructureValue.record_value = _.without(
            structure.recordStructureValue.record_value,
            null
        )
        structures.push(structure.recordStructureValue)
      })
      _.forEach(structuresCopy.groups, group => {
        _.forEach(group.rows, row => {
          _.forEach(row.values, structure => {
            this.transformStructure(structure)
            structure.recordStructureValue.groupRow = row.groupRow
            structures.push(structure.recordStructureValue)
          })
        })
      })
      item.recordStructureValues = structures
      item.location_destination = null
      item.location_origin = null
      item.recordStructureValuesOrganized = null
      items.recordList.push(item)
      await this.save(items, document, this.documents, this.editedIndex)
    },
    // Save Records
    async save(items, document, documents, editedIndex) {
      this.loading = true;
      await this.saveRecord([
        items,
        document,
        documents,
        editedIndex,
        this.$toast,
      ]).finally(() => {
        this.loading = false;
        this.$router.push({path: '/prerecords'})
      })
    },
    transformStructure(structure) {
      if (["TIME", "DATATIME", "DATA"].includes(structure.structure.structure_type)) {
        if (structure.recordStructureValue.record_value &&
          structure.recordStructureValue.record_value.length > 0 &&
          structure.recordStructureValue.record_value[0] !== null) {
          let date = moment(structure.recordStructureValue.record_value[0].toString().slice(0, 24)).format();
          structure.recordStructureValue.record_value[0] = date.slice(0, 19);
        } else {
          structure.recordStructureValue.record_value = [];
        }
      } else if (structure.structure.structure_type === "NUMBER") {
        if (structure.recordStructureValue.record_value[0] === "" ||
          structure.recordStructureValue.record_value[0] === null) {
          if (!structure.structure.is_required)
            structure.recordStructureValue.record_value = [];
          else
            structure.recordStructureValue.record_value = [parseFloat(0).toFixed(this.decimalFormat(structure))];
        } else {
          let value = structure.recordStructureValue.record_value[0].toString();
          let [integerPart, decimalPart = ""] = value.split(".");
          const requiredDecimals = this.decimalFormat(structure);
          if (decimalPart.length > requiredDecimals) {
            decimalPart = decimalPart.slice(0, requiredDecimals);
          } else {
            decimalPart = decimalPart.padEnd(requiredDecimals, '0');
          }
          structure.recordStructureValue.record_value[0] = integerPart + (decimalPart ? "." + decimalPart : "");
        }
      }
    },
    replace(event) {
      event.currentTarget.value = event.currentTarget.value
          .toString()
          .replaceAll('"', '')
    },
    replacePrerecordsName(event) {
      this.preRecordSelected.prerecords_name = event.currentTarget.value
          .toString()
          .replaceAll('"', '')
    },
  },
}
</script>

<style scoped></style>
